@import 'styles/theme';

.PushGeneric {
  margin-top: theme($spacings, 20);
  margin-bottom: theme($spacings, 20);

  .pushTitle,
  .item {
    grid-column: 1/-1;
  }

  .pushTitle {
    @include from('md') {
      grid-row: span 2;
      grid-column: 1/9;

      &.hasRegularItem {
        grid-row: 1;
      }
    }
  }

  .item {
    & + .item {
      margin-top: theme($spacings, 8);
    }

    @include from('md') {
      grid-column: 9/-1;

      &.isRegular {
        grid-row: 2;
        grid-column: 1/9;
      }
    }
  }
}
