@import 'styles/theme';

.PushTitleLink {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: theme($spacings, 160);
  padding-bottom: theme($spacings, 160);

  &:not(.withPaddings) {
    @include from('md') {
      padding-top: 0;
      align-items: flex-start;
      padding-bottom: 0;
    }
  }

  &.theme-concrete {
    background: theme($colors, 'concrete');
  }

  .label {
    & + .cta {
      margin-top: theme($spacings, 5);
    }
  }

  .title {
    text-align: center;
  }
}
