@use 'sass:math';
@import 'styles/theme';

.ProductPinTag {
  display: flex;

  .link,
  .container {
    display: flex;
  }

  .image {
    width: theme($spacings, 70);
  }

  .content {
    padding: theme($spacings, 10);
    width: theme($spacings, 220);
  }

  .topLine {
    display: flex;
    justify-content: space-between;
  }

  .designer + .name {
    margin-top: theme($spacings, 5);
  }

  .mockButton {
    margin-top: theme($spacings, 6);
  }
}
