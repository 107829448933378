@use 'sass:math';
@import 'styles/theme';

.StoreCard {
  .cardImages {
    position: relative;
  }

  .ratio {
    @include applyPercentRatios(math.div(3, 4));
  }

  .hoverImage {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .cardBottom {
    display: flex;
    margin-top: theme($spacings, 16);
    align-items: center;

    .streetNumber {
      margin-right: theme($spacings, 20);
    }
  }
}
