@use 'sass:math';
@import 'styles/theme';

.ProductsPinsPush {
  @include switchDesktopMobileClasses('lg');
  @include below('lg') {
    padding: 0;
  }

  .container {
    grid-column: 1 / -1;
    overflow: hidden;
  }
}
