@use 'sass:map';
@use 'sass:math';

// Shortcut for map-get
@function theme($key, $value) {
  @return map-get($key, $value);
}

// Mixin to generate mobile-first repsonsive breakpoints
@mixin from($screenName) {
  @media screen and (min-width: map-get($screens, $screenName)) {
    @content;
  }
}

@mixin below($screenName) {
  @media screen and (max-width: map-get($screens, $screenName)) {
    @content;
  }
}

@function vw($vw) {
  @return calc(#{$vw * 1vw} - var(--scroll-bar));
}

@function vh($vh) {
  @return calc(var(--vh100) / 100 * #{$vh});
}

@function stableVh($vh: 100) {
  @return calc($vh * var(--stable-vh, 1vh));
}

// Mixin that switches from stableVh to variable vh value
@mixin toggleStableVh($size, $breakpoint: 'lg', $propertyName: 'height') {
  #{$propertyName}: stableVh($size);

  @include from($breakpoint) {
    #{$propertyName}: vh($size);
  }
}

// Mixin to convert px to em
@function em($pixels, $context: map-get($fontSizes, 16)) {
  @return #{$pixels/$context}em;
}

// Mixin for fluid types
@function stripUnit($value) {
  @return math.div($value, ($value * 0 + 1));
}

// theme mixin
@mixin setThemeClasses($themes: '') {
  @if $themes != '' {
    @each $theme, $themeColors in $themes {
      &.#{$theme}Theme,
      .#{$theme}Theme {
        @each $color, $hexa in $themeColors {
          --theme-#{$color}: #{$hexa};
        }
      }
    }
  } @else {
    @each $color, $hexa in $colors {
      &.#{$color}Theme,
      .#{$color}Theme {
        --theme-main: #{$hexa};
      }
    }
  }
}

@mixin applyFluidType(
  $propertyName,
  $minVw,
  $maxVw,
  $minFontSize,
  $maxFontSize,
  $direction: 1
) {
  $u1: unit($minVw);
  $u2: unit($maxVw);
  $u3: unit($minFontSize);
  $u4: unit($maxFontSize);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      #{$propertyName}: $minFontSize;
      @media screen and (min-width: $minVw) {
        #{$propertyName}: calc(
          #{$direction} * (#{$minFontSize} + #{stripUnit(
                  $maxFontSize - $minFontSize
                )} * ((100vw - #{$minVw}) / #{stripUnit($maxVw - $minVw)}))
        );
      }
      @media screen and (min-width: $maxVw) {
        #{$propertyName}: $direction * $maxFontSize;
      }
    }
  }
}

@mixin fluidFontSizeType($minVw, $maxVw, $minFontSize, $maxFontSize) {
  @include applyFluidType(
    'font-size',
    $minVw,
    $maxVw,
    $minFontSize,
    $maxFontSize
  );
}

// Scaled font size mixin to be called when font is fluid
@mixin scaledFontSize($value) {
  @include from('xxl') {
    font-size: min(
      calc(
        (#{stripUnit($value)} / #{stripUnit(theme($max, 'width-design'))}) *
          100vw
      ),
      #{getMaxValue($value)}
    );
  }

  @include from('max-scale-width') {
    font-size: #{getMaxValue($value)};
  }
}

// Get scaled value to apply between min & max values
@function getScaledValue($value) {
  @return calc(
    (#{stripUnit($value)} / #{stripUnit(theme($max, 'width-design'))}) * 100vw
  );
}

// Get max value relative to design value & grid max value
@function getMaxValue($value) {
  @return calc(
    (#{theme($max, 'width-scale')} * #{stripUnit($value)}) / #{stripUnit(
        theme($max, 'width-design')
      )}
  );
}

// Generate a clamped value with with min, scaled & max values
@function getClampedValue($value) {
  @return clamp(#{$value}, #{getScaledValue($value)}, #{getMaxValue($value)});
}

@function getGridWidthWithoutGuttersAndGaps($size) {
  @return calc(
    #{vw(100)} - (#{theme($size, 'gutter')} * 2) - ((
            #{theme($size, 'columns')} - 1
          ) * #{theme($size, 'gap')})
  );
}

@function getGridMaxWidthWithoutGuttersAndGaps($size) {
  @return calc(
    (#{theme($max, 'width')} - var(--scroll-bar-grid)) -
      (#{theme($size, 'gutter')} * 2) -
      ((#{theme($size, 'columns')} - 1) * #{theme($size, 'gap')})
  );
}

// Function to get the width of a column
@function getGridColumnWidth($numberOfColumns, $size) {
  @return calc(
    (
        #{getGridWidthWithoutGuttersAndGaps($size)} / #{theme($size, 'columns')} *
          #{$numberOfColumns}
      ) + (#{theme($size, 'gap')} * max(0, (#{$numberOfColumns - 1})))
  );
}

// Function to get the max-width of a column
@function getGridColumnMaxWidth($numberOfColumns, $size) {
  @return calc(
    (
        #{getGridMaxWidthWithoutGuttersAndGaps($size)} / #{theme(
            $size,
            'columns'
          )} * #{$numberOfColumns}
      ) + (#{theme($size, 'gap')} * max(0, (#{$numberOfColumns} - 1)))
  );
}

// Mixin to get the width of a column
@mixin gridColumnWidth($numberOfColumns: 1, $size) {
  width: getGridColumnWidth($numberOfColumns, $size);
  max-width: getGridColumnMaxWidth($numberOfColumns, $size);
}

// Call to get appropriate grid columns width & max-width properties w/ breakpoint already set
@mixin columns(
  $params: (
    'mobile': 1,
    'desktop': 1,
  )
) {
  @include gridColumnWidth(map-get($params, 'mobile'), $mobile);

  @include from('lg') {
    @include gridColumnWidth(map-get($params, 'desktop'), $desktop);
  }
}

// Apply column width to a property
@mixin applyColumnWidth($propertyName, $numberOfColumns, $size) {
  #{$propertyName}: getGridColumnWidth($numberOfColumns, $size);

  @include from('max-grid-width') {
    #{$propertyName}: getGridColumnMaxWidth($numberOfColumns, $size);
  }
}

// Call to set a property to a column value w/ breakpoint already set
@mixin applyColumns(
  $params: (
    'propertyName': '',
    'mobile': 1,
    'desktop': 1,
  ),
  $breakpoint: 'lg'
) {
  @include applyColumnWidth(
    map-get($params, 'propertyName'),
    map-get($params, 'mobile'),
    $mobile
  );

  @include from($breakpoint) {
    @include applyColumnWidth(
      map-get($params, 'propertyName'),
      map-get($params, 'desktop'),
      $desktop
    );
  }
}

@mixin setPropertyValue(
  $params: (
    'values': $desktop,
    'propertyName': '',
    'gutters': 0,
    'gaps': 0,
    'columns': 0,
  )
) {
  #{map-get($params, 'propertyName')}: calc(
    #{getGridColumnWidth(
        map-get($params, 'columns'),
        map-get($params, 'values')
      )} + (
        #{theme(map-get($params, 'values'), 'gutter')} * #{map-get(
            $params,
            'gutters'
          )}
      ) + (#{theme(map-get($params, 'values'), 'gap')} * #{map-get(
            $params,
            'gaps'
          )})
  );

  @include from('max-grid-width') {
    #{map-get($params, 'propertyName')}: calc(
      #{getGridColumnMaxWidth(
          map-get($params, 'columns'),
          map-get($params, 'values')
        )} + (
          #{theme(map-get($params, 'values'), 'gutter')} * #{map-get(
              $params,
              'gutters'
            )}
        ) + (#{theme(map-get($params, 'values'), 'gap')} * #{map-get(
              $params,
              'gaps'
            )})
    );
  }
}

// Generate padding bottom from ratio presets
@mixin genPaddingBottomFromRatio($ratio) {
  padding-bottom: calc(1 / #{$ratio} * 100%);
}

// Generate classes
@mixin generateClassesFromList($list, $propertyName) {
  @each $listItem in $list {
    .#{$propertyName}-#{$listItem} {
      #{$propertyName}: $listItem;
    }
  }
}

@mixin generateClassesFromMap(
  $list,
  $propertyName,
  $altPropertiesName: [],
  $pseudoElements: []
) {
  @each $listKey, $listItem in $list {
    .#{$listKey} {
      #{$propertyName}: $listItem;

      @if altPropertiesName {
        @each $altPropertyName in $altPropertiesName {
          #{$altPropertyName}: $listItem;
        }
      }

      @if pseudoElements {
        @each $pseudoElement in $pseudoElements {
          &::#{$pseudoElement} {
            #{$propertyName}: $listItem;
          }
        }
      }
    }
  }
}

@function getPercentRatio($ratio) {
  @return calc(1 / $ratio) * 100%;
}

@mixin applyPercentRatios($default, $ratios: null) {
  padding-bottom: getPercentRatio($default);

  @if $ratios {
    @each $name, $ratio in $ratios {
      @include from($name) {
        padding-bottom: getPercentRatio($ratio);
      }
    }
  }
}

@mixin switchDesktopMobileClasses($breakpoint: 'lg', $display: block) {
  .hideOnSmallScreen,
  &.hideOnSmallScreen {
    display: none;

    @include from($breakpoint) {
      display: $display;
    }
  }
  .hideOnLargeScreen,
  &.hideOnLargeScreen {
    @include from($breakpoint) {
      display: none;
    }
  }
}

@mixin mapToProps($map, $key: 'toto') {
  @if type-of($map) == map {
    @each $prop, $value in $map {
      @if type-of($value) != map {
        @if inspect($prop) != 'description' {
          #{$key}___#{$prop}: #{inspect($value)};
        }
      } @else {
        @include mapToProps($value);
      }
    }
  }
}

@mixin setContainerContext($name, $type: inline-size) {
  container-type: $type;
  container-name: $name;
}
