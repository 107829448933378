@use 'sass:math';
@import 'styles/theme';

.HeroSlider {
  overflow: hidden;

  @include from('md') {
    height: stableVh(100);
  }

  .item {
    width: 100vw;

    @include from('md') {
      width: math.div(960, 1440) * 100vw;
    }
  }

  @include switchDesktopMobileClasses('md', block);
}
