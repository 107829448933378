@use 'sass:math';
@import 'styles/theme';

.TwoColumnsImageTitleWysiwygs {
  margin-bottom: theme($spacings, 20);
  margin-top: theme($spacings, 20);

  .image {
    grid-column: 1 / -1;
    margin-top: theme($spacings, 20);
    grid-row: 2;
    @include from('md') {
      grid-row: 1;
      grid-column: 1 / 9;
      margin-top: 0;
    }
  }
  .content {
    grid-column: 1 / -1;
    @include from('md') {
      grid-column: 9 / 25;
    }

    display: flex;
    flex-direction: column;
  }

  .wysiwygs {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    column-gap: theme($spacings, 8);

    @include from('md') {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .title {
    width: 100%;
    @include from('md') {
      margin-left: theme($spacings, 40);
      margin-right: theme($spacings, 40);
      @include applyColumnWidth('width', 8, $desktop);
    }
    + .wysiwygs {
      margin-top: theme($spacings, 20);
      @include from('md') {
        margin-top: theme($spacings, 40);
      }
    }
  }

  .wysiwyg {
    &:first-child {
      @include from('md') {
        margin-left: theme($spacings, 40);
        margin-right: theme($spacings, 40);
      }
    }
    + .wysiwyg {
      margin-top: theme($spacings, 20);

      @include from('md') {
        margin-top: 0;
      }
    }
  }

  .ratio {
    @include applyPercentRatios(math.div(462, 650));
  }

  // Variants
  &.isReversed {
    @include from('md') {
      .image {
        grid-column: 16 / 25;
      }

      .content {
        grid-column: 1 / 16;
      }
    }
  }
}
