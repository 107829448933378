@import 'styles/theme';

.PushStores {
  margin-top: theme($spacings, 40);
  margin-bottom: theme($spacings, 40);
  overflow: hidden;

  @include from('md') {
    margin-top: theme($spacings, 20);
    margin-bottom: theme($spacings, 20);
  }
  .top,
  .body {
    grid-column: 1/-1;
  }

  .top {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: theme($spacings, 20);

    @include from('md') {
      flex-direction: row;
      align-items: baseline;
      margin-bottom: theme($spacings, 40);
    }

    &.withControls {
      @include from('md') {
        margin-bottom: theme($spacings, 20);
      }
    }
  }

  .title {
    @include from('md') {
      padding-right: theme($spacings, 20);
    }
  }

  .cta {
    margin-top: theme($spacings, 10);

    @include from('md') {
      margin-left: auto;
    }
  }

  .prev,
  .next {
    display: flex;
    align-items: center;
    justify-self: center;
    .icon {
      width: theme($spacings, 16);
      fill: black;

      path {
        fill: black;
      }
    }
  }

  .prev {
    transform: scale(-1);
  }

  .sliderControls {
    display: none;

    @include from('md') {
      display: flex;
      justify-content: flex-end;
      margin-bottom: theme($spacings, 20);

      .slideIndicator {
        display: flex;
        align-items: center;
        margin-left: theme($spacings, 10);
        margin-right: theme($spacings, 10);

        & > :not(.activeIndex) {
          opacity: 0.5;
        }
      }
    }
  }

  .empty {
    width: theme($spacings, 16);
  }

  .slider {
    width: 100%;

    .item {
      display: block;

      @include applyColumns(
        (
          'propertyName': 'width',
          'desktop': 8,
          'mobile': 15,
        )
      );

      @include setPropertyValue(
        (
          'values': $mobile,
          'propertyName': 'margin-right',
          'gutters': 0,
          'gaps': 1,
          'columns': 0,
        )
      );
    }
  }
}
