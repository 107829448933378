@use 'sass:math';
@import 'styles/theme';

.GenericCard {
  display: flex;
  position: relative;
  height: 300px;
  overflow: hidden;

  @include from('md') {
    height: 620px;
  }

  .hoverImage {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .video {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .content {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    padding: theme($spacings, 20);
  }

  .cta {
    pointer-events: none;
  }

  .title {
    color: theme($colors, 'white');
    margin-bottom: theme($spacings, 2);

    @include from('md') {
      margin-bottom: theme($spacings, 5);
    }

    &.black {
      color: theme($colors, 'black');
    }
  }

  // Layouts
  &.layout-regular {
    height: 500px;

    @include from('md') {
      height: 620px;
    }
  }
}
