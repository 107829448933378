@import 'styles/theme';

.EditorialTitleParagraphCta {
  text-align: center;
  margin-top: theme($spacings, 150);
  margin-bottom: theme($spacings, 150);

  @include from('md') {
    margin-top: theme($spacings, 120);
    margin-bottom: theme($spacings, 120);
  }

  .title,
  .cta {
    grid-column: 1/-1;
  }

  .title {
    margin-bottom: theme($spacings, 10);

    @include from('md') {
      margin-bottom: theme($spacings, 16);
      grid-column: 8/-8;
    }
  }

  .subtitle {
    grid-column: 2/-2;

    @include from('md') {
      grid-column: 8/-8;
    }
  }

  .cta {
    margin-top: theme($spacings, 16);

    @include from('md') {
      grid-column: 10/-10;
    }
  }
}
