@use 'sass:math';
@import 'styles/theme';

.ThreeColumnsImageWysiwyg {
  @include switchDesktopMobileClasses('md');
  row-gap: theme($spacings, 8);

  .image {
    grid-column: span 8;
  }

  .wysiwyg {
    grid-column: 1 / -1;
    @include from('md') {
      grid-column: span 8;
    }
  }
  .ratio {
    @include applyPercentRatios(math.div(462, 650));
  }

  .wysiwyg {
    margin-top: theme($spacings, 8);

    + .wysiwyg {
      @include from('md') {
        margin-top: theme($spacings, 12);
      }
    }
  }
}
