@import 'styles/theme';

.SwitchImage {
  display: block;
  position: relative;
  overflow: hidden;

  .image {
    height: 100%;
    background-color: theme($colors, 'white');
  }

  .next {
    position: absolute;
    inset: 0;
    z-index: 2;
  }

  .current {
    position: relative;
    z-index: 3;
  }

  &.animate {
    .current {
      animation: opacity-out 0.3s ease-out forwards;
    }
  }
}

@keyframes opacity-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
