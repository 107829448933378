@import 'styles/theme';

.ProductsGrid {
  margin-top: theme($spacings, 20);
  margin-bottom: theme($spacings, 20);

  .left,
  .right {
    grid-column: 1/-1;
  }

  .left {
    @include from('md') {
      grid-column: 1/9;
    }
  }

  .right {
    display: flex;
    flex-flow: column wrap;
    row-gap: theme($spacings, 8);

    @include from('md') {
      display: flex;
      flex-flow: row wrap;
      grid-column: 9/-1;
    }

    .item {
      @include applyColumnWidth('width', 16, $mobile);

      @include from('md') {
        @include applyColumnWidth('width', 8, $desktop);
        @include setPropertyValue(
          (
            'values': $desktop,
            'propertyName': 'margin-right',
            'gutters': 0,
            'gaps': 1,
            'columns': 0,
          )
        );

        &:nth-child(even) {
          margin-right: 0;
        }
      }
    }
  }
}
