@use 'sass:math';
@import 'styles/theme';

.PushCategories {
  position: relative;
  margin-top: theme($spacings, 20);
  margin-bottom: theme($spacings, 20);

  @include switchDesktopMobileClasses('md');

  @include from('md') {
    height: vh(100);
    overflow: hidden;
  }

  .image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .ratio {
    @include applyPercentRatios(math.div(16, 9));
  }

  .grid {
    height: 100%;
  }

  .categories {
    grid-column: 1/-1;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;

    @include from('md') {
      position: relative;
      display: flex;
      z-index: 2;
    }

    .cta {
      & + .cta {
        margin-left: clamp(
          getGridColumnWidth(1, $desktop),
          theme($spacings, 10),
          theme($spacings, 60)
        );
      }

      &:not(.isActive) {
        opacity: 0.4;
      }
    }
  }

  .mobileCategories {
    display: flex;
    flex-direction: column;

    .card {
      position: relative;
    }

    .label {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      &.white-theme {
        color: theme($colors, 'white');
      }

      &.black-theme {
        color: theme($colors, 'black');
      }
    }
  }
}
