@use 'sass:math';
@import 'styles/theme';

.PushSocial {
  margin-top: theme($spacings, 20);
  margin-bottom: theme($spacings, 20);
  overflow: hidden;

  .left,
  .right {
    grid-column: 1/-1;
  }

  .left {
    @include from('md') {
      grid-column: 1/9;
    }
  }

  .right {
    @include from('md') {
      grid-column: 9/-1;
    }
    z-index: 2;
  }

  .slider {
    width: 100%;
    display: flex;
    overflow: hidden;

    .item {
      display: block;

      @include applyColumns(
        (
          'propertyName': 'width',
          'desktop': 8,
          'mobile': 15,
        )
      );

      @include setPropertyValue(
        (
          'values': $mobile,
          'propertyName': 'margin-right',
          'gutters': 0,
          'gaps': 1,
          'columns': 0,
        )
      );
    }
  }

  .ratio {
    @include applyPercentRatios(math.div(3, 4));
  }
}
