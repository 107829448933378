@use 'sass:math';
@import 'styles/theme';

.HeroSliderItem {
  position: relative;

  .image {
    width: 102%;
  }

  .ratio {
    @include genPaddingBottomFromRatio(math.div(6, 7));
    @include from('md') {
      padding-bottom: 0;
      height: stableVh(100);
    }
  }

  .content {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    bottom: 0;
    width: 100%;
    padding: theme($spacings, 40);
    box-sizing: border-box;
  }

  .title {
    display: inline-flex;
    pointer-events: none;
    white-space: normal;
  }

  .subtitle {
    margin-top: theme($spacings, 4);
  }
}
