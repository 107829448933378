@use 'sass:math';
@import 'styles/theme';

.PinMarker {
  @include switchDesktopMobileClasses('lg');
}

.tagContainer {
  @include switchDesktopMobileClasses('lg');
  @include below('lg') {
    top: 50%;
    left: 50%;
  }

  position: absolute;
  height: theme($spacings, 34);
  width: theme($spacings, 34);
  transform: translate3d(-50%, -50%, 0);
  pointer-events: none;
  z-index: 2;
}

.tag {
  position: absolute;
  pointer-events: all;

  &.hidden {
    display: none;
  }

  @include below('lg') {
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }

  @include from('lg') {
    &.HorizontalHalf-2 {
      left: auto;
      right: theme($spacings, 60);
    }

    &.HorizontalHalf-1 {
      left: theme($spacings, 60);
    }

    &.VerticalThird-1 {
      top: 0;
    }

    &.VerticalThird-3 {
      top: auto;
      bottom: 0;
    }

    &.VerticalThird-2 {
      top: auto;
      transform: translate3d(0, 0, 0);
    }
  }
}

.Toggle {
  height: theme($spacings, 30);
  width: theme($spacings, 30);
  transform: translate3d(-50%, -50%, 0);
  transform-origin: 50% 50%;
  border-width: 1px;
  border-style: solid;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  pointer-events: all;
  z-index: 0;

  .icon {
    width: theme($spacings, 16);
    transform: rotate(45deg);
    transition: 0.15s transform linear;
  }

  @include from('md') {
    height: theme($spacings, 34);
    width: theme($spacings, 34);

    .icon {
      width: theme($spacings, 18);
    }
  }

  &.isActive {
    .icon {
      transform: rotate(0);
    }
  }
}
