@import 'styles/theme';

.BigTitle {
  margin-top: theme($spacings, 20);
  margin-bottom: theme($spacings, 20);

  @include from('md') {
    margin-top: theme($spacings, 40);
    margin-bottom: theme($spacings, 40);
  }

  .titleVariable {
    grid-column: 1/-1;
  }
}
